<template>
  <view-wrapper class="trailer" header-text="Trailer" no-offset>
    <youtube-video
      :video-id="TRAILER_YOUTUBE_ID"
      :controls="true"
      poster-src="/img/poster.png"
      poster-delay="250">
    </youtube-video>
  </view-wrapper>
</template>

<script>
import { TRAILER_YOUTUBE_ID } from '@/utils/constants'

export default {
  name: 'trailer',

  data() { 
    return {
      TRAILER_YOUTUBE_ID
    }
  }
}
</script>
